import { showNotification } from "../../utils/notification";
import Loader from "../global/Loader";

const { faSync } = require("@fortawesome/free-solid-svg-icons");
const { FontAwesomeIcon } = require("@fortawesome/react-fontawesome");
const { t } = require("i18next");
const { useMemo, useEffect, useState } = require("react");
const { Button } = require("react-bootstrap");

function Captcha({ clientApi, captchaToken, setCaptchaToken, captchaCode, setCaptchaCode }) {
    const [refreshActive, setRefreshActive] = useState(false);

    const captchaImageUrl = useMemo(() => {
        if (captchaToken) {
            return clientApi.getCaptchaImageUrl(captchaToken);
        } else {
            return '';
        }
    }, [clientApi, captchaToken]);

    const [refreshTrigger, setRefreshTrigger] = useState(false);

    // On mount obtain new captcha token
    useEffect(() => {
        setRefreshActive(true);

        const fetchCaptchaToken = async () => {
            setRefreshActive(true);

            try {
                const newCaptchaToken = await clientApi.makeCaptcha();
                setCaptchaToken(newCaptchaToken);
            } catch (error) {
                showNotification(t("captchaTokenFetchError") + ": " + error.message, 'danger');
                console.error(error);
            }

            setRefreshActive(false);
        }

        const delayedFetch = setTimeout(fetchCaptchaToken, 200);
        const intervalFetch = setInterval(fetchCaptchaToken, (1000 * 60 * 5)); // 5 minutes

        return () => {
            clearTimeout(delayedFetch);
            clearInterval(intervalFetch);
        };
    }, [clientApi, refreshTrigger, setCaptchaToken]);

    return (
        captchaToken && (
            <div className="captcha d-flex align-items-center my-2">
                {/* Captcha image */}
                <img
                    src={captchaImageUrl}
                    alt="Captcha"
                    className="captcha__image"
                />

                {/* Refresh button */}
                {refreshActive ? (
                    <div className="captcha__loader ms-1">
                        <Loader compact />
                    </div>
                ) : (
                <Button
                    variant="secondary"
                    size="sm"
                    className="captcha__button ms-1"
                    onClick={() => setRefreshTrigger(!refreshTrigger)}
                >
                    <FontAwesomeIcon icon={faSync} />
                </Button>
                )}

                {/* Captcha code label */}
                <label
                    className="captcha__label ms-1"
                    htmlFor="captcha-code"
                >
                    {t("captchaCodeLabel")}
                </label>

                {/* Captcha code input */}
                <input
                    className="captcha__input ms-1"
                    type="text"
                    placeholder={t("captchaCodePlaceholder")}
                    value={captchaCode}
                    onChange={e => setCaptchaCode(e.target.value)}
                    aria-label="Captcha code"
                />
            </div>
        )
    );
}

export default Captcha;
