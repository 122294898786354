import { useCallback, useState } from "react";
import { downloadBlob } from "../../utils/blob";
import { t } from "i18next";
import { showNotification } from "../../utils/notification";
import { Button, Card, Form } from "react-bootstrap";

function ExportMeasurementsForm({ cartApi, cartId, clientMeasurementApi }) {
    const [format, setFormat] = useState('docx');
    const [addImages, setAddImages] = useState(true);
    const [addOptimalLasts, setAddOptimalLasts] = useState(false);
    const [hideNames, setHideNames] = useState(false);

    const exportMeasurements = useCallback(async () => {
        const cartExportParams = {
            addImages: addImages,
            addOptimalLasts: addOptimalLasts,
            hideNames: hideNames
        };

        try {
            if (cartApi) {
                const data = await cartApi.exportMeasurements(cartId, format, cartExportParams);
                const filename = t("cartMeasurementsExportFilename") + "." + format;
                downloadBlob(data, filename);
            } else if (clientMeasurementApi) {
                if (!clientMeasurementApi.ready()) {
                    throw new Error(t("clientMeasurementsExportNotReady"));
                }

                const data = await clientMeasurementApi.exportMeasurements(format, cartExportParams);
                const filename = t("clientMeasurementsExportFilename") + "." + format;
                downloadBlob(data, filename);
            }
        } catch (err) {
            console.log(err);
            showNotification(t("measurementsExportFailed") + ": " + err, 'danger');
        }
    }, [addImages, addOptimalLasts, cartApi, cartId, clientMeasurementApi, format, hideNames]);

    return (
        <Card>
            <Card.Body>
                <Card.Text>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>{t("measurementsExportFormat")}</Form.Label>
                            <Form.Check
                                type="radio"
                                id="formatDocx"
                                name="format"
                                label={t("exportFormatDocx")}
                                value={'docx'}
                                checked={format === 'docx'}
                                onChange={e => setFormat(e.target.value)}
                            />
                            <Form.Check
                                type="radio"
                                id="formatXlsx"
                                name="format"
                                label={t("exportFormatXlsx")}
                                value={'xlsx'}
                                checked={format === 'xlsx'}
                                onChange={e => setFormat(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Check
                                type="checkbox"
                                id="addImages"
                                label={t("exportAddImages")}
                                checked={addImages}
                                onChange={e => setAddImages(e.target.checked)}
                            />
                            <Form.Check
                                type="checkbox"
                                id="addOptimalLasts"
                                label={t("exportAddOptimalLasts")}
                                checked={addOptimalLasts}
                                onChange={e => setAddOptimalLasts(e.target.checked)}
                            />
                            <Form.Check
                                type="checkbox"
                                id="hideNames"
                                label={t("exportHideNames")}
                                checked={hideNames}
                                onChange={e => setHideNames(e.target.checked)}
                            />
                        </Form.Group>
                        
                        <Form.Group className="mb-3">
                            <Button variant="primary" onClick={exportMeasurements}>{t("exportMeasurements")}</Button>
                        </Form.Group>
                    </Form>
                </Card.Text>
            </Card.Body>
        </Card>
    );
}

export default ExportMeasurementsForm;
