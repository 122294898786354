class CartData {
    constructor(cartId, cartItems, cartName, cartSubmitted) {
        this.cartId = cartId || null;
        this.cartItems = cartItems || [];
        this.cartName = cartName || '';
        this.cartSubmitted = cartSubmitted || false;
    }

    _makeCartItem(item, size, insole, humanName) {
        let cartItem = {
            modelId: item.modelId,
            modelName: item.name,
            modelType: item.type,
            modelBrand: item.brand,
            article: item.article,
            price: item.price,
            smallImage: item.images?.[0],
            quantity: 1,
            size: size
        }

        if (item.last) {
            cartItem.lastName = item.last;
        }

        if (item.fitnessEstimate) {
            cartItem.fitnessId = item.fitnessEstimate.fitnessId;
            cartItem.measurementId = item.fitnessEstimate.measurementId;
        }

        if (insole) {
            cartItem.insoleId = insole.id;
            cartItem.insoleName = insole.name;
        }

        if (humanName) {
            cartItem.humanName = humanName;
        }

        return cartItem;
    }

    _compareCartItem(cartItem, item, size, insole) {
        return cartItem.modelId === item.modelId &&
            (!size || cartItem.size === size) &&
            (!insole || cartItem.insoleId === insole.id) &&
            (!item.fitnessEstimate || cartItem.measurementId === item.fitnessEstimate.measurementId);
    }

    _compareCartItems(cartItem1, cartItem2) {
        return cartItem1.modelId === cartItem2.modelId &&
            cartItem1.size === cartItem2.size &&
            cartItem1.insoleId === cartItem2.insoleId &&
            cartItem1.measurementId === cartItem2.measurementId;
    }

    add(item, size, insole, humanName) {
        return this.addItems(this.cartItems, item, size, insole, humanName);
    }

    addItems(cartItems, item, size, insole, humanName) {
        if (this.checkItems(cartItems, item, size, insole)) {
            const cartItem = this.findItems(cartItems, item, size, insole);
            return this.updateQuantityItems(cartItems, cartItem, cartItem.quantity + 1);
        } else {
            const cartItem = this._makeCartItem(item, size, insole, humanName);
            return [
                ...cartItems,
                cartItem
            ];
        }
    }

    updateQuantity(cartItem, quantity) {
        return this.updateQuantityItems(this.cartItems, cartItem, quantity);
    }

    updateQuantityItems(cartItems, cartItem, quantity) {
        return cartItems.map((cartItem2) => {
            if (this._compareCartItems(cartItem, cartItem2)) {
                return {
                    ...cartItem2,
                    quantity: quantity
                }
            } else {
                return cartItem2;
            }
        });
    }

    check(item, size, insole) {
        return this.checkItems(this.cartItems, item, size, insole);
    }

    checkItems(cartItems, item, size, insole) {
        return cartItems.some((cartItem) => this._compareCartItem(cartItem, item, size, insole));
    }

    find(item, size, insole) {
        return this.findItems(this.cartItems, item, size, insole);
    }

    findItems(cartItems, item, size, insole) {
        return cartItems.find((cartItem) => this._compareCartItem(cartItem, item, size, insole));
    }

    remove(cartItem) {
        return this.removeItems(this.cartItems, cartItem);
    }

    removeItems(cartItems, cartItem) {
        return cartItems.filter((cartItem2) => !this._compareCartItems(cartItem, cartItem2));
    }
}

export default CartData;
