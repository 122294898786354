class CartApi {
    constructor(sessionId) {
        this.sessionId = sessionId;
    }

    async create() {
        const endpoint = process.env.REACT_APP_SHOESERVER_URL + '/api/createCart';

        const headers = {
            'Accept': 'application/json',
            'X-API-Key': process.env.REACT_APP_SHOESERVER_API_KEY,
        };

        if (this.sessionId) {
            headers['X-Session-ID'] = this.sessionId;
        }

        const response = await fetch(endpoint, { headers: headers });

        if (!response.ok) {
            throw new Error(response.statusText);
        }

        const data = await response.json();
        return data;
    }

    async load(cartId) {
        const endpoint = process.env.REACT_APP_SHOESERVER_URL + '/api/getCart';

        const params = new URLSearchParams({ cartId: cartId });

        const headers = {
            'Accept': 'application/json',
            'X-API-Key': process.env.REACT_APP_SHOESERVER_API_KEY,
        };

        if (this.sessionId) {
            headers['X-Session-ID'] = this.sessionId;
        }

        const response = await fetch(endpoint + "?" + params, { headers: headers });

        if (!response.ok) {
            throw new Error(response.statusText);
        }

        const data = await response.json();
        return data;
    }

    async getCarts(page, limit) {
        const endpoint = process.env.REACT_APP_SHOESERVER_URL + '/api/getCarts';

        const params = new URLSearchParams();
        if (page) {
            params.append('page', page);
        }
        if (limit) {
            params.append('limit', limit);
        }

        const headers = {
            'Accept': 'application/json',
            'X-API-Key': process.env.REACT_APP_SHOESERVER_API_KEY,
        };
        if (this.sessionId) {
            headers['X-Session-ID'] = this.sessionId;
        }

        const response = await fetch(endpoint + "?" + params, { headers: headers });

        if (!response.ok) {
            throw new Error(response.statusText);
        }

        const data = await response.json();
        return data;
    }

    async save(cartId, cartItems, cartName) {
        const endpoint = process.env.REACT_APP_SHOESERVER_URL + '/api/saveCart';

        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-API-Key': process.env.REACT_APP_SHOESERVER_API_KEY,
        };

        if (this.sessionId) {
            headers['X-Session-ID'] = this.sessionId;
        }

        let requestData = {
            "cartId": cartId,
            "items": cartItems
        };

        if (cartName) {
            requestData["name"] = cartName;
        }

        const response = await fetch(endpoint, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(requestData),
        });

        if (!response.ok) {
            throw new Error(response.statusText);
        }
    }

    async submit(cartId, cartItems, cartName) {
        const endpoint = process.env.REACT_APP_SHOESERVER_URL + '/api/submitCart';

        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-API-Key': process.env.REACT_APP_SHOESERVER_API_KEY,
        };

        if (this.sessionId) {
            headers['X-Session-ID'] = this.sessionId;
        }


        let requestData = {
            "cartId": cartId,
            "items": cartItems
        };

        if (cartName) {
            requestData["name"] = cartName;
        }

        const response = await fetch(endpoint, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(requestData),
        });

        if (!response.ok) {
            throw new Error(response.statusText);
        }
    }


    async deleteCart(cartId) {
        const endpoint = process.env.REACT_APP_SHOESERVER_URL + '/api/deleteCart';

        const params = new URLSearchParams({ cartId: cartId });

        const headers = {
            'Accept': 'application/json',
            'X-API-Key': process.env.REACT_APP_SHOESERVER_API_KEY,
        };
        if (this.sessionId) {
            headers['X-Session-ID'] = this.sessionId;
        }

        const response = await fetch(endpoint + "?" + params, { headers: headers });

        if (!response.ok) {
            throw new Error(response.statusText);
        }
    }


    async exportCart(cartId, format, cartExportParams) {
        const endpoint = process.env.REACT_APP_SHOESERVER_URL + '/api/exportCart';
        const params = new URLSearchParams({ cartId: cartId, format: format, ...cartExportParams });

        const headers = {
            'X-API-Key': process.env.REACT_APP_SHOESERVER_API_KEY,
        };
        if (this.sessionId) {
            headers['X-Session-ID'] = this.sessionId;
        }

        const response = await fetch(endpoint + "?" + params, { headers: headers });
        if (!response.ok) {
            throw new Error(response.statusText);
        }
        
        const data = await response.blob();
        return data;
    }

    async exportMeasurements(cartId, format, cartExportParams) {
        const endpoint = process.env.REACT_APP_SHOESERVER_URL + '/api/exportCartMeasurements';
        const params = new URLSearchParams({ cartId: cartId, format: format, ...cartExportParams });

        const headers = {
            'X-API-Key': process.env.REACT_APP_SHOESERVER_API_KEY,
            'X-Session-ID': this.sessionId
        };

        const response = await fetch(endpoint + "?" + params, { headers: headers });
        if (!response.ok) {
            throw new Error(response.statusText);
        }
        
        const data = await response.blob();
        return data;
    }

    getExportUrl(cartId, format, cartExportParams) {
        return process.env.REACT_APP_SHOESERVER_URL + '/api/exportCart?' + new URLSearchParams({
            cartId: cartId,
            format: format,
            attachment: true,
            ...cartExportParams
        });
    }

    getMeasurementsExportUrl(cartId, format, cartExportParams) {
        return process.env.REACT_APP_SHOESERVER_URL + '/api/exportCartMeasurements?' + new URLSearchParams({
            cartId: cartId,
            format: format,
            attachment: true,
            ...cartExportParams
        });
    }

    makeShareLink(cartId) {
        return window.location.protocol + "//" + window.location.host + "/share/shoppingList/" + cartId;
    }
}

export default CartApi;
