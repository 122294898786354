import { Badge, Button, Col, Row } from "react-bootstrap";
import { isoDateToString } from "../../utils/date";
import { t } from "i18next";
import outputNumber from "../../utils/number";
import { Trans } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { sexToString } from "../../utils/sex";
import { useMemo, useState } from "react";
import ConfirmationDialog from "../global/ConfirmationDialog";

function EmployeeDetails({ employee, riskFactorNames, climaticNames, onEdit, onDelete }) {
    const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);

    function getEmployeeDetails(employee) {
        let details = [];

        if (employee?.first_name) {
            details.push({ key: t("employeeFirstName"), value: employee.first_name });
        }

        if (employee?.last_name) {
            details.push({ key: t("employeeLastName"), value: employee.last_name });
        }

        if (employee?.employee_number) {
            details.push({ key: t("employeeNumber"), value: employee.employee_number });
        }

        if (employee?.department) {
            details.push({ key: t("employeeDepartment"), value: employee.department });
        }

        if (employee?.profession) {
            details.push({ key: t("employeeProfession"), value: employee.profession });
        }

        if (employee?.birth_date) {
            details.push({ key: t("employeeBirthDate"), value: isoDateToString(employee.birth_date) });
        }

        if (employee?.admission_date) {
            details.push({ key: t("employeeAdmissionDate"), value: isoDateToString(employee.admission_date) });
        }

        if (employee?.last_change_date) {
            details.push({ key: t("employeeLastChangeDate"), value: isoDateToString(employee.last_change_date) });
        }

        if (employee?.sex) {
            details.push({ key: t("employeeSex"), value: sexToString(employee.sex) });
        }

        if (employee?.height) {
            details.push({ key: t("employeeHeight"), value: outputNumber(employee.height, t("cm"), 0) });
        }

        if (employee?.weight) {
            details.push({ key: t("employeeWeight"), value: outputNumber(employee.weight, t("kg"), 0) });
        }

        if (employee?.climatic_summer) {
            const climaticName = climaticNames?.find(climatic => climatic.codeName === employee.climatic_summer)?.name;

            if (climaticName) {
                details.push({ key: t("employeeClimaticSummer"), value: climaticName });
            } else {
                details.push({ key: t("employeeClimaticSummer"), value: employee.climatic_summer });
            }
        }

        if (employee?.climatic_winter) {
            const climaticName = climaticNames?.find(climatic => climatic.codeName === employee.climatic_winter)?.name;

            if (climaticName) {
                details.push({ key: t("employeeClimaticWinter"), value: climaticName });
            } else {
                details.push({ key: t("employeeClimaticWinter"), value: employee.climatic_winter });
            }
        }

        return details;
    }

    const employeeRiskFactors = useMemo(() => {
        if (!employee?.risk_factors) {
            return null;
        }

        return employee.risk_factors
            .map(rf => riskFactorNames?.find(rfn => rfn.codeName === rf))
            .filter(rf => rf !== undefined);
    }, [employee, riskFactorNames]);

    return (
        <>
            <Row>
                {getEmployeeDetails(employee).map(detail => [
                    <Col xs={6} lg={3} className="text-muted" key={detail.key}>{detail.key}</Col>,
                    <Col xs={6} lg={3} key={detail.key + '_value'}>{detail.value}</Col>
                ]).flat()}

                <Col xs={6} lg={3} className="text-muted"><Trans>employeeMeasurement</Trans></Col>
                <Col xs={6} lg={3}>
                    {employee?.measurement_ids?.map(id => <a key={id} className="me-1" href={`/measurement?measurementId=${id}`}>{id}</a>)}
                </Col>

                {employeeRiskFactors?.length > 0 && (
                    <Col xs={12}>
                        <div>
                            <Trans>employeeRiskFactors</Trans>:

                            <ul className="list-unstyled">
                                {employeeRiskFactors.map(rf => (
                                    <li key={rf.codeName}><Badge className="me-1">{rf.codeName}</Badge>{rf.name}</li>
                                ))}
                            </ul>
                        </div>
                    </Col>
                )}
            </Row>

            {onEdit && onDelete && (
                <Row>
                    <Col>
                        <div className="d-flex justify-content-center">
                            <Button variant="link" onClick={onEdit}><FontAwesomeIcon icon={faEdit} /> <Trans>Edit</Trans></Button>
                            <Button variant="link" className="text-danger" onClick={() => setDeleteConfirmationOpen(true)}><FontAwesomeIcon icon={faTrash} /> <Trans>Delete</Trans></Button>
                        </div>

                        <ConfirmationDialog
                            show={deleteConfirmationOpen}
                            title={t("employeeDelete")}
                            message={t("employeeDeleteMessage")}
                            confirmText={t("Delete")}
                            variant="danger"
                            onConfirm={() => {
                                setDeleteConfirmationOpen(false);
                                onDelete(employee.id);
                            }}
                            onCancel={() => setDeleteConfirmationOpen(false)}
                        />
                    </Col>
                </Row>
            )}
        </>
    );
}

export default EmployeeDetails;
