import { useCallback, useState } from "react";
import { downloadBlob } from "../../utils/blob";
import { t } from "i18next";
import { showNotification } from "../../utils/notification";
import { Button, Card, Form } from "react-bootstrap";

function ExportCartForm({ cartApi, cartId }) {
    const [format, setFormat] = useState('docx');
    const [addFitnessResult, setAddFitnessResult] = useState(false);
    const [hideNames, setHideNames] = useState(false);

    const exportCart = useCallback(async () => {
        const cartExportParams = {
            addFitnessResult: addFitnessResult,
            hideNames: hideNames
        };

        try {
            const data = await cartApi.exportCart(cartId, format, cartExportParams);
            const filename = t("cartExportFilename") + " " + cartId + "." + format;
            downloadBlob(data, filename);
        } catch (err) {
            console.log(err);
            showNotification(t("cartExportFailed") + ": " + err, 'danger');
        }
    }, [addFitnessResult, cartApi, cartId, format, hideNames]);

    return (
        <Card>
            <Card.Body>
                <Card.Text>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>{t("cartExportFormat")}</Form.Label>
                            <Form.Check
                                type="radio"
                                id="formatDocx"
                                name="format"
                                label={t("exportFormatDocx")}
                                value={'docx'}
                                checked={format === 'docx'}
                                onChange={e => setFormat(e.target.value)}
                            />
                            <Form.Check
                                type="radio"
                                id="formatXlsx"
                                name="format"
                                label={t("exportFormatXlsx")}
                                value={'xlsx'}
                                checked={format === 'xlsx'}
                                onChange={e => setFormat(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Check
                                type="checkbox"
                                id="addFitnessResult"
                                label={t("exportAddFitnessResult")}
                                checked={addFitnessResult}
                                onChange={e => setAddFitnessResult(e.target.checked)}
                            />
                            <Form.Check
                                type="checkbox"
                                id="hideNames"
                                label={t("exportHideNames")}
                                checked={hideNames}
                                onChange={e => setHideNames(e.target.checked)}
                            />
                        </Form.Group>
                        
                        <Form.Group className="mb-3">
                            <Button variant="primary" onClick={exportCart}>{t("exportCart")}</Button>
                        </Form.Group>
                    </Form>
                </Card.Text>
            </Card.Body>
        </Card>
    );
}

export default ExportCartForm;
